export const regions = [
  {
    name: "APAC",
    values: [
      "Asia/Baghdad",
      "Asia/Baku",
      "Asia/Hong_Kong",
      "Asia/Karachi",
      "Asia/Shanghai",
      "Asia/Magadan",
      "Asia/Jerusalem",
      "Asia/Singapore",
      "Asia/Manila",
      "Pacific/Auckland",
    ],
  },
  {
    name: "AU",
    values: [
      "Australia/Brisbane",
      "Australia/Melbourne",
      "Australia/Perth",
      "Australia/Sydney",
    ],
  },
  {
    name: "EU",
    values: [
      "Europe/Amsterdam",
      "Europe/Berlin",
      "Europe/Madrid",
      "Europe/Paris",
      "Europe/Stockholm",
      "Europe/Warsaw",
      "Europe/Helsinki",
      "Europe/Athens",
      "Europe/Rome",
      "Pacific/Noumea",
    ],
  },
  { name: "UK", values: ["Europe/London"] },
  { name: "IE", values: ["Europe/Dublin"] },
  {
    name: "US",
    values: [
      "America/Chicago",
      "America/Denver",
      "America/Los_Angeles",
      "America/New_York",
      "America/Port_of_Spain",
      "America/Halifax",
      "America/Phoenix",
      "Pacific/Midway",
      "Pacific/Honolulu",
    ],
  },
]

export function getRegion(timezone: string) {
  return regions.find((region) => region.values.includes(timezone))
}
